<template>
  <div class="corporate">
    <!-- 头部 -->
    <div class="neironga">
      <div style="display: inline-block;"><img src="../../assets/shequ.png" alt="" /></div>
      <div style="display: inline-block;">
        <div style="position: absolute;top:20px;left:80px">
          <div>{{list.Name}}</div>
        <div style="font-size:12px;margin-top:6px;">
          <span>{{list.ETypeDesc}}</span>
        </div>
        </div>
        <!-- <div style="position: absolute;right:15px;top:17px">
          <div style="font-size:20px;padding:0px 25px">6</div>
          <div>在招职位</div>
        </div> -->
      </div>
    </div>
    <!-- 简介 -->
    <div class="neirongb">
      <div>公司简介</div>
      <p>{{list.Summary}}</p>
    </div>
    <div class="neirongb">
      <div>公司规模</div>
      <p>成立日期: {{list.FoundDate}}</p>
      <p>注册资本: {{list.RegCapital}}</p>
      <p>企业规模: {{list.CoScale}}人</p>
      <p>负 责 人:{{list.Charger}}</p> 
      <p> 电 话 : {{list.CoTel}}</p>
    </div>
    <div class="neirongb">
      <div>公司地址</div>
      <p>地 址: {{list.Address}}</p>
      <!-- <p>邮箱:{{list.CoEMail}}</p> -->
      <!-- <p>主页:{{list.Homepage}}</p> -->
      <!-- <p>微博:{{list.MicroBlog}}</p> -->
      <!-- <p>微信公众号:{{list.WeChat}}</p> -->
    </div>
    <!-- 在招职位 -->
    <!-- <div class="neirongc">
      <div style="font-size: 18px;">在招职位</div>
      <div class="neirongd">
        <div style="position: absolute;top: 10px;
    left: 15px;font-size:18px">软件开发师</div>
        <div style="position: absolute;left:15px;bottom:10px">
          <span style="color:red">8-10K</span>
          <span style="margin:0px 8px">|</span>
          <span>5-10年</span>
          <span style="margin:0px 8px">|</span>
          <span>本科</span>
        </div>
        <div style="position: absolute;right:15px;top:27px">招聘中</div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { WxGetEnterprise } from "@/api/ResumeJS"
export default {
  data(){
    return{
      list:[], //详情内容
    }
  },
  methods:{
    Enterprise(row){
      WxGetEnterprise({eId:row}).then((res)=>{
        this.list = res.data.data;
        console.log(this.list);
      })
    }
  },
  created(){
    var eId = this.$route.query.eId;
    this.Enterprise(eId);
  }
}
</script>
<style scoped>
.corporate .neironga {
  position: relative;
  background: #444c5f;
  color: #fff;
}
.corporate .neironga img{
  width: 40%;
  margin: 10px;
}
.corporate .neirongb  {
  width: 90%;
  margin: 8px 5%;
}
.corporate .neirongb div{
  border-left: 3px solid #0084f8;
  padding-left: 8px;
  font-size: 18px;
}
.corporate .neirongb p{
  font-size: 14px;
}
.corporate .neirongc {
  width: 90%;
  margin: 8px 5%;
}
.corporate .neirongd {
  position: relative;
  margin: 10px 0px;
  background: #f1f3f5;
  border-radius: 15px;
  height: 80px;
}
</style>